import React from "react";
import ListLinks from "../ListLinks/ListLinks";
import { listInfo } from "../ListLinks/listsContent";

import "./Footer.scss";

import logo from '../../assets/logo-base.svg';
import twitterIcon from '../../assets/twitter-logo.svg';
import discordIcon from '../../assets/discord-logo.svg';

const Footer = () => {
  return <footer>
    <div className="footer">
      <div className="socials-container1">
        <img className="logo" src={logo} alt=""/>
        <div className="socials">
          <a href="https://twitter.com/FungifyNFT"><img src={twitterIcon} alt=""/></a>
          <a href="https://discord.gg/fungifynft"><img src={discordIcon} alt=""/></a>
        </div>
      </div>
      <ListLinks header={listInfo.title} list={listInfo.list}/>
    </div>
  </footer>;
}

export default Footer;
