import React from "react";
import BackDecoration from "../../BackDecoration/BackDecoration";
import LandingHeader from "../../Header/LandingHeader/LandingHeader";
import Footer from "../../Footer/Footer";

import "../Landing.scss";
import "./LandingLayout.scss";

export default function LandingLayout({children}) {

  return <div className="container layout">
    <LandingHeader />
    <main className="main layout">
      {children}
    </main>
    <BackDecoration />
    <Footer />
  </div>;
}
