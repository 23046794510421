import React from "react";

import "./Menu.scss";
import logo from "../../assets/logo-base.svg";
import diamond from "../../assets/diamond.svg";

const Menu = () => {

  return <nav className="nav">
      <input type="checkbox" id="nav-check" />
        <div className="nav-header">
          <div className="nav-title">
            <img className="nav-logo" src={logo} alt=""/>
          </div>
        </div>
        <div className="nav-btn">
          <label className="nav-btn-label" htmlFor="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>

        <ul className="nav-list">
          <li>
            <a href="https://blog.fungify.it/p/fungify-competition-guide">Help</a>
          </li>
          <li>
            <a className="button-like-link" href="https://testnet.fungify.it/wallet">
              <img src={diamond} alt=""/>
              Use App
            </a>
          </li>
        </ul>
    </nav>
};

export default Menu;
