import React from "react";
import Button from "../Button/Button";

import "./LeaderBoard.scss";

import leaderboard from "../../assets/leaderboard.svg";
import spiceLogo from "../../assets/spice-logo.svg";
import nftperpLogo from "../../assets/nftperp-logo.svg";

const leaderBoardData = {
  teams: [
    {
      id: 1,
      name: "SPICE",
      logo: spiceLogo,
      totalScore: 40000
    },
    {
      id: 2,
      name: "NFTPERP",
      logo: nftperpLogo,
      totalScore: 465800
    }
  ],
  boardRecords: [
    {
      id: 1,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "SPICE",
      teamId: 1
    },
    {
      id: 2,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "NFTPERP",
      teamId: 2
    },
    {
      id: 3,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "SPICE",
      teamId: 1
    },
    {
      id: 4,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "NFTPERP",
      teamId: 2
    },
    {
      id: 5,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "NFTPERP",
      teamId: 2
    },
    {
      id: 6,
      score: "465.800",
      wallet: "0x000...000A",
      teamName: "SPICE",
      teamId: 1
    },
  ]
}

const generateTeamData = (team) => {
  return <>
    <p className="team-name">TEAM</p>
    <img className="team-logo" src={team.logo} alt=""/>
    <p className="team-score-title">TOTAL SCORE</p>
    <p className="team-score">{team.totalScore}</p>
  </>
}

const generateRecord = (record) => {
  const teamClass = record.teamId === leaderBoardData.teams[0].id ? "left" : "right";

  return <div key={`record-${record.id}`} className="board-row board-record">
    <p className="record-id">{getPlaceWithSuffix(record.id)}</p>
    <p className="record-score">{record.score}</p>
    <p className="record-wallet">{record.wallet}</p>
    <p className={`record-team-name ${teamClass}`}>{record.teamName}</p>
  </div>
}

const getPlaceWithSuffix = (place) => {
  const j = place % 10,
    k = place % 100;
  if (j === 1 && k !== 11) {
    return place + "st";
  }
  if (j === 2 && k !== 12) {
    return place + "nd";
  }
  if (j === 3 && k !== 13) {
    return place + "rd";
  }
  return place + "th";
}

const LeaderBoard = () => {

  return (
    <div className="leader-board">
      <div className="title">
        <img className="title-img" src={leaderboard} alt=""/>
        <h1 className="title-txt">LEADERBOARD</h1>
      </div>

      <div className="teams">
        <div className="team left">
          {generateTeamData(leaderBoardData.teams[0])}
        </div>
        <div className="team-vs">VS</div>
        <div className="team right">
          {generateTeamData(leaderBoardData.teams[1])}
        </div>
      </div>

      <div className="board">
        <div className="board-row board-header">
          <p>RANK</p>
          <p>SCORE</p>
          <p className="with-label">
            WALLET
            <span className="label">YOU</span>
          </p>
          <p>TEAM</p>
        </div>
        {
          leaderBoardData.boardRecords.map(record =>
            generateRecord(record))
        }
      </div>

      <div className="leader-board-button">
        <Button style={{borderRadius: "24px", fontSize: "16px", padding: "15px 60px"}}>BACK TO APP</Button>
      </div>
    </div>
  );
};

export default LeaderBoard;
