import React from "react";

import logo from '../../../assets/logo-base.svg';
import mushroom from '../../../assets/mushroom.svg';
import leaderboard from '../../../assets/leaderboard.svg';
import ring from '../../../assets/ring.svg';
import sun from '../../../assets/sun.svg';
import wallet from '../../../assets/wallet.svg';
import currency from '../../../assets/currency.svg';

import "./WalletHeader.scss";

const WalletHeader = () => {
  return <header className="header wallet">
    <div className="logo"><img src={logo} alt=""/></div>
    <nav className="menu">
      <ul className="left-menu">
        <li>
          <a href="/">
            <img src={mushroom} alt=""/>
          </a>
        </li>
        <li>
          <a href="/" className="active">
            <img src={leaderboard} alt=""/>
          </a>
        </li>
        <li>
          <a href="/">Marketplace</a>
        </li>
        <li>
          <a href="/">Stats</a>
        </li>
      </ul>
      <ul className="right-menu">
        <li>
          <div className="menu-elem icon-only">
            <img src={ring} alt=""/>
          </div>
        </li>
        <li>
          <div className="menu-elem icon-only">
            <img src={sun} alt=""/>
          </div>
        </li>
        <li>
          <div className="menu-elem">
            <img src={wallet} alt=""/>
            <p>0x...0000</p>
          </div>
        </li>
        <li>
          <div className="menu-elem">
            <img src={currency} alt=""/>
            <p>1,500.00</p>
          </div>
        </li>
      </ul>
    </nav>
  </header>;
}


export default WalletHeader;
