import React from 'react'
import { Tab } from 'semantic-ui-react'
import { tabsContent } from './tabsContent'

import "./Tabs.scss";

const generateTabElement = () => {
  return tabsContent.map((content) =>
    <div>
      <div className="tab-text-wrapper">
      <p className="tab-text">{`${content.text} Learn `}
        <a
          href="https://blog.fungify.it/p/fungify-competition-guide"
          style={{color: 'inherit', cursor: 'pointer',textDecoration: 'underline'}}
        >more</a>.
      </p>
      </div>
      <img className="tab-img" src={content.img} alt=""/>
    </div>
  )
}

const panes = tabsContent.map((content, index) => {
  return { menuItem: content.title, render: () =>
      <Tab.Pane>
        {generateTabElement()[index]}
      </Tab.Pane>
  }
})

// at-todo animation when changing tabs
const Tabs = () => <Tab panes={panes} />

export default Tabs;
