import React from "react";

import "./ListLinks.scss";

const ListLinks = ({ header, list}) => {
  return (
    <div className="list-links">
      <p>{header}</p>
      <ul>
        {list.map((link, index) =>
          <li key={`list-link-${index}`}><a href={link.href}>{link.text}</a></li>)
        }
      </ul>
    </div>
  );
};

export default ListLinks;
