import React from "react";
import LeaderBoard from "../../components/LeaderBoard/LeaderBoard";

import "./Wallet.scss";

const Wallet = () => {
  return <div className="wallet">
    <LeaderBoard />
  </div>;
}

export default Wallet;
