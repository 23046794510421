import React from "react";

import "./BackDecoration.scss";

const stripesSet = {
  left: ["pink", "orange", "yellow", "green"],
  right: ["blue", "green", "purple", "pink"]
}

const getStripes = (side) => {
  return stripesSet[side].map((stripe, index) =>
    <div key={`stripe-${side}-${index}`} className={`stripe ${stripe}`}></div>
  )
}

const BackDecoration = () => {

  return <div className="back-decoration">
    <div className='stripe-set left'>
      {getStripes("left")}
    </div>

    <div className='stripe-set right'>
      {getStripes("right")}
    </div>
  </div>;
}

export default BackDecoration;
