import React from "react";
import {useRoutes} from "react-router-dom";
import {landingRoutes, walletRoutes} from "./routes";

function App() {
  const landingRouteResult = useRoutes(landingRoutes);
  const walletRouteResult = useRoutes(walletRoutes);
  return (
    <>
      {landingRouteResult}
      {walletRouteResult}
    </>
  );
}

export default App;
