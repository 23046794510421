import React from "react";

import "./Button.scss";

const Button = ({ onClick, children, icon, size = "", style = {}}) => {

  return (
    <button type="button" className={`button ${size}`} onClick={onClick} style={style}>
      {icon ? <img src={icon} alt=""/> : null}
      {children}
    </button>
  );
};

export default Button;
