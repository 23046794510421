import React from "react";
import BackDecoration from "../../BackDecoration/BackDecoration";
import WalletHeader from "../../Header/WalletHeader/WalletHeader";

import "../Landing.scss";
import "./WalletLayout.scss";

export default function WalletLayout({children}) {
  return <div className="container wallet">
    <WalletHeader />
    <main className="main wallet">
      {children}
    </main>
    <BackDecoration />
  </div>;
}
