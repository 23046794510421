import mayc from "../../assets/mayc.svg";
import airdrop from "../../assets/airdrop.svg";
import pudgy from "../../assets/pudgy.svg";
import milady from "../../assets/milady.svg";

export const imageCards = [
  {
    title: "MAYC",
    img: mayc
  },
  {
    title: "$FUNG Airdrop",
    img: airdrop
  },
  {
    title: "Pudgy Penguin",
    img: pudgy
  },
  {
    title: "Milady",
    img: milady
  }
]
