import React from "react";
import Home from "../pages/Home/Home";
import Wallet from "../pages/Wallet/Wallet";
import LandingLayout from "../components/Layout/LandingLayout/LandingLayout";
import WalletLayout from "../components/Layout/WalletLayout/WalletLayout";

const getLandingLayout = (page) => <LandingLayout>{page}</LandingLayout>;

export const landingRoutes = [
  {
    path: "/",
    element: getLandingLayout(<Home />),
  },
  // {
  //   path: "about",
  //   children: [
  //     {
  //       index: true,
  //       element: getLandingLayout(<About />),
  //     }
  //   ],
  // }
];

export const walletRoutes = [
  {
    path: "wallet",
    children: [
      {
        index: true,
        element: <WalletLayout><Wallet /></WalletLayout>,
      }
    ],
  }
];
