import Button from "../../components/Button/Button";
import React from "react";
import ImgCards from "../../components/ImgCards/ImgCards";
import Tabs from "../../components/Tabs/Tabs";
import Title from "../../components/Title/Title";

import "./Home.scss";

import banner from "../../assets/hero-banner.png";
import logo from "../../assets/logo-fancy.svg";
import tradeProfitWin from "../../assets/trade-profit-win.svg";
import starDecorationL from "../../assets/star-decoration-l.svg";
import starDecorationR from "../../assets/star-decoration-r.svg";
import console from "../../assets/console.svg";
import waysToWinTxt from "../../assets/ways-to-win-txt.svg";
import yourTeamIsWaiting from "../../assets/yourTeamIsWaiting.svg";
import vs1 from "../../assets/vs1.svg";
import overviewTxt from "../../assets/overview-txt.svg";
import colouredMushrooms from "../../assets/coloured-mushrooms.svg";
import colouredMushroom from "../../assets/coloured-mushroom.svg";

const Home = () => {
  return <div className="home">
    <div className="hero section">
      <Title leftIcon={starDecorationL} rightIcon={starDecorationR} hideMobileIcons={true} customClass="hero">
        <img className="hero-logo" src={logo} alt=""/>
        <img className="hero-text" src={tradeProfitWin} alt=""/>
        <p className="hero-subtitle">Use the protocol. Make money. Win prizes.</p>
      </Title>
      <div className="banner">
        <video autoPlay muted loop poster={banner}>
          <source src="/video/compressed.mp4" type="video/mp4"/>
        </video>
      </div>
    </div>

    <div className="sign-up section">
      <div className="console-decoration">
        <img src={console} alt="" />
        <a href="https://docs.google.com/forms/d/1WyFNVHZaju78E11Ngam3Ae4CvtY9FYmPHE-J0C_5QJI/viewform?pli=1&pli=1&edit_requested=true" style={{textDecoration: 'none'}}>
          <Button size="big">Sign Up</Button>
        </a>
      </div>
    </div>

    <div className="image-cards section">
      <ImgCards />
    </div>

    <div className="divider divider-middle"></div>

    <div className="ways-to-win section">
      <Title leftIcon={colouredMushroom} customClass="ways-to-win">
        <img className="ways-to-win-title" src={waysToWinTxt} alt="" />
      </Title>

      <div className="txt block">
        <p>Your goal is simple: have the largest portfolio.</p>
        <p>Make degen moves, be an arbitragooor, or index yield farmer.</p>
        <p>The choice is yours. Here are the tools Fungify unlocks:</p>
      </div>

      <div className="tabs block">
        <Tabs />
      </div>
    </div>

    <div className="divider divider-small"></div>

    <div className="join section">
      <div className="overview-r">
        <img src={yourTeamIsWaiting} alt="your team is waiting" />
      </div>
      <div className="overview-l">
        <img src={vs1} alt="" />
      </div>
    </div>

    <div className="divider"></div>

    <div className="overview section">
      <div className="overview-l">
        <img src={colouredMushrooms} alt="" />
      </div>
      <div className="overview-r">
        <img src={overviewTxt} alt="" />
        <div className="overview-txt">
          <p>Once accepted, you’ll be placed on a team.</p>
          <p>The competition will score you both on an individual as well as a team leaderboard.</p>
          <p>Rise in the ranks to win rewards and prizes.</p>
        </div>
        <div className="overview-buttons-wrapper">
        <a className="button-like-link" href="https://docs.google.com/forms/d/1WyFNVHZaju78E11Ngam3Ae4CvtY9FYmPHE-J0C_5QJI/viewform?pli=1&pli=1&edit_requested=true"
           style={{borderRadius: "24px", width: "188px", padding: "11px 0"}}>
          Apply Here
        </a>
        <a className="button-like-link" href="https://blog.fungify.it/p/fungify-competition-guide"
           style={{borderRadius: "24px", width: "188px", padding: "11px 0", backgroundColor: '#60b4e5'}}>
          Learn more
        </a>
      </div>
      </div>
    </div>
  </div>;
}

export default Home;
