import React from "react";
import Menu from "../../Menu/Menu";

import "./LandingHeader.scss";

const LandingHeader = () => {
  return <header className="header landing">
    <Menu />
  </header>;
}

export default LandingHeader;
