import React from "react";
import { imageCards } from './cardsContent'
import Title from "../Title/Title";

import "./ImgCards.scss";

import prize from "../../assets/prize.svg";
import prizesTxt from "../../assets/prizes-txt.svg";

const ImgCards = () => {
  return (
    <>
      <Title leftIcon={prize} customClass="prizes">
        <img className="prizes-title" src={prizesTxt} alt=""/>
      </Title>

      <div className="cards">
        {
          imageCards.map((card, index) => {
            return <div key={`img-card-${index}`} className="card">
              <img src={card.img} alt={card.title} />
              <p>{card.title}</p>
            </div>
          })
        }
      </div>
    </>
  );
};

export default ImgCards;
