export const listHelp = {
  title: "HELP",
  list: [
    {
      text: "FAQ",
      href: "https://www.google.com/"
    },
    {
      text: "Buying Guides",
      href: "https://www.google.com/"
    },
    {
      text: "Selling Guides",
      href: "https://www.google.com/"
    },
    {
      text: "Get Appraised",
      href: "https://www.google.com/"
    },
    {
      text: "Redemption",
      href: "https://www.google.com/"
    }
  ]
};

export const listInfo = {
  title: "INFORMATION",
  list: [
    {
      text: "Docs",
      href: "https://docs.fungify.it/"
    },
    {
      text: "Blog",
      href: "https://blog.fungify.it/"
    },
    // {
    //   text: "About Fungify",
    //   href: "https://www.google.com/"
    // },
    // {
    //   text: "Press",
    //   href: "https://www.google.com/"
    // },
    // {
    //   text: "Backers",
    //   href: "https://www.google.com/"
    // },
    // {
    //   text: "Contribute",
    //   href: "https://www.google.com/"
    // }
  ]
};

export const listDisclosures = {
  title: "DISCLOSURES",
  list: [
    {
      text: "Terms & Conditions",
      href: "https://www.google.com/"
    },
    {
      text: "Privacy",
      href: "https://www.google.com/"
    },
    {
      text: "Security",
      href: "https://www.google.com/"
    }
  ]
};
