import React from "react";

import "./Title.scss";

const Title = ({ children, leftIcon, rightIcon, hideMobileIcons, customClass }) => {
  const hideClass = hideMobileIcons ? "hide-mobile" : "";

  return (
    <div className={`title ${customClass}`}>
      <img className={`title-decoration ${hideClass}`} src={leftIcon} alt=""/>
      <div className="title-content">
        {children}
      </div>
      <img className={`title-decoration ${hideClass}`} src={rightIcon || leftIcon} alt=""/>
    </div>
  );
};

export default Title;
