import tabImg1 from "../../assets/long-the-nft-img.png";
import tabImg2 from "../../assets/short-the-nft-img.png";
import tabImg3 from "../../assets/arbitrage-img.png";

export const tabsContent = [
  {
    title: "Long",
    text: "Buy low, sell high! Leverage your assets to amplify buying power and potential returns. Prefer broad exposure? Opt for the index, going long on the entire NFT market.",
    img: tabImg1
  },
  {
    title: "Short",
    text: "Sell high, buy low. Go short, capitalize on the dips, and profit. Lever up to multiply gains. If you're betting against the entire NFT market, now you can short the entire index!",
    img: tabImg2
  },
  {
    title: "Arbitrage the Peg",
    text: "See the market price of NFT index token deviate from the peg? Arb it and profit.",
    img: tabImg3
  }
]
